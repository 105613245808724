import clsx from "clsx";
import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import Icon from "shared/components/icon/Icon";
import { TagsField } from "shared/components/tags-field/TagsField";

type TArticleTagsDropdownProps = {
  readonly disabled?: boolean;
};

const ArticleTagsDropdown = ({ disabled }: TArticleTagsDropdownProps) => {
  const {
    addTags,
    articleQuery: { data: article },
  } = useArticleContext();

  if (!article) return null;

  const selectedTags = article.tags?.map(({ name }) => ({ value: name, label: name, data: name }));

  return (
    <>
      <div className="flex gap-4 flex-wrap">
        {selectedTags
          ?.map((tag) => {
            return (
              <div
                key={tag.value}
                className="flex justify-between items-center gap-1 px-1.5 py-0.5 rounded-md bg-[#E1EFFE]"
              >
                <div className={clsx("text-sm font-medium text-crayolasblue")}>{tag.label}</div>
                {!disabled ? (
                  <button
                    disabled={!article?.isActive}
                    onClick={() => {
                      const filteredTags = selectedTags.filter((item) => item.value !== tag.value);
                      addTags(filteredTags.map((tag) => tag.value));
                    }}
                  >
                    <Icon type="close-small" fill={true} size="icon-xxs" badgeColor="#1C64F2" />
                  </button>
                ) : null}
              </div>
            );
          })
          .concat([
            <DropdownMenu.Root key={article.id}>
              <DropdownMenu.Trigger asChild disabled={disabled || !article.isActive}>
                <button
                  aria-label="Customise options"
                  title="Change View"
                  disabled={disabled}
                  className="focus-visible:outline-none"
                >
                  {selectedTags?.length > 0 ? (
                    <span className="block add_btn p-0.5 border border-brightgray rounded shadow-sm">
                      {!disabled ? <Icon type="plus-btn" fill={true} size="icon-xs" /> : null}
                    </span>
                  ) : (
                    <div className="flex items-center gap-1">
                      <span className="text-sm font-inter-medium text-santagrey dark:text-gray_400">
                        Select tags
                      </span>
                      <Icon type="chevron-down" size="icon-sm" fill={true} />
                    </div>
                  )}
                </button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content
                className="z-10 relative min-w-[15rem]"
                align="start"
                sideOffset={5}
                side="bottom"
              >
                <TagsField
                  teamId={undefined}
                  controlShouldRenderValue={false}
                  isDisabled={disabled}
                  values={selectedTags.map((tag) => ({ label: tag.label, value: tag.value }))}
                  onChange={(data) => {
                    if (!article.isActive) return;
                    addTags(data.map((tag) => String(tag.value)));
                  }}
                />
              </DropdownMenu.Content>
            </DropdownMenu.Root>,
          ])}
      </div>
    </>
  );
};

export default ArticleTagsDropdown;
