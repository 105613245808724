import Icon, { IconSize } from "shared/components/icon/Icon";

export const TodoItemIcon = ({
  totalTodosAmount,
  completedTodosAmount,
  size = "icon-xs",
}: {
  totalTodosAmount: number;
  completedTodosAmount: number;
  size?: string;
}) => {
  const allCompleted = totalTodosAmount === completedTodosAmount;
  const iconType = allCompleted
    ? "check-circle"
    : completedTodosAmount > 0
    ? "in-progress"
    : "to-do";

  return (
    <Icon type={iconType} size={size as IconSize} className={allCompleted ? "stroke-none" : ""} />
  );
};
