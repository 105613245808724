import { useState } from "react";

import clsx from "clsx";

import styles from "assets/css/articlesV2.module.css";

import Icon from "shared/components/icon/Icon";

import MetadataField from "./components/metadata-field";

interface IMetadataProps {
  metadatas: {
    label: string;
    meta: JSX.Element | string;
  }[];
  collapsible?: boolean;
  sectionTitle?: string;
}

const ArticleMetadata = ({ collapsible, metadatas, sectionTitle }: IMetadataProps) => {
  const [isExpanded, setIsExpanded] = useState(!collapsible);

  return (
    <div className="grid border border-antiflashwhite rounded-lg px-6 py-3 dark:border-jet">
      {sectionTitle || collapsible ? (
        <div
          className={clsx("flex items-center justify-between", { "cursor-pointer": collapsible })}
          onClick={() => collapsible && setIsExpanded((prev) => !prev)}
        >
          {sectionTitle ? (
            <div className="flex items-center text-sm text-richblack font-medium gap-2 dark:text-gray-400">
              <Icon type="bars-3-bottom-left" size="icon-xs" />
              {sectionTitle}
            </div>
          ) : null}
          {collapsible ? (
            <Icon
              className={clsx("transition-transform", { "rotate-180": !isExpanded })}
              type="chevron-up"
              size="icon-xs"
            />
          ) : null}
        </div>
      ) : null}
      <div
        className={clsx(styles.article_details_collapsible, {
          [styles.article_details_collapsible_expanded]: isExpanded,
        })}
      >
        <div className={styles.article_details_collapsible_content}>
          <div className={clsx("grid gap-2", { "pt-4": sectionTitle || collapsible })}>
            {metadatas.map((metadata) => (
              <MetadataField key={metadata.label} {...metadata} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleMetadata;
