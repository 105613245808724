import { client } from "client";
import { PaginatedTodoList, Todo, TodoRequest } from "openapi";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useArticleContext } from "../contexts/ArticleContext";

export const useArticleTodos = () => {
  const { feedId, articleId, companyId, isPublic } = useArticleContext();
  return useQuery({
    queryKey: ["article-todos", { feedId, companyId, articleId }],
    queryFn: () =>
      client.horizonScanning.horizonFeedsArticlesTodosList({
        feedId,
        companyId,
        articleId,
        ordering: "created_at",
      }),
    enabled: !isPublic,
  });
};

export const useArticleTodoCreate = () => {
  const { feedId, articleId, companyId } = useArticleContext();
  const queryClient = useQueryClient();
  const createTodo = useMutation({
    mutationFn: (todoRequest: TodoRequest) =>
      client.horizonScanning.horizonFeedsArticlesTodosCreate({
        feedId,
        companyId,
        articleId,
        todoRequest,
      }),
    onSuccess: () => {
      console.log("onSuccess");
      queryClient.invalidateQueries({
        queryKey: ["article-todos", { feedId, companyId, articleId }],
      });
    },
    onMutate: ({ ...todoRequest }) => {
      console.log("onMutate", todoRequest);
      queryClient.setQueryData(
        ["article-todos", { feedId, companyId, articleId }],
        (oldData?: PaginatedTodoList) => {
          console.log("setQueryData", oldData);
          if (!oldData) return undefined;
          return {
            ...oldData,
            results: [
              ...oldData.results,
              {
                ...todoRequest,
                id: Math.random(),
                createdAt: new Date(),
                updatedAt: new Date(),
              } as Todo,
            ],
          };
        }
      );
    },
  });

  const updateTodo = useMutation({
    mutationFn: ({ id, ...todoRequest }: TodoRequest & { id: number }) =>
      client.horizonScanning.horizonFeedsArticlesTodosUpdate({
        feedId,
        companyId,
        articleId,
        id,
        todoRequest,
      }),
    onMutate: ({ id, ...todoRequest }) => {
      queryClient.setQueryData(
        ["article-todos", { feedId, companyId, articleId }],
        (oldData?: PaginatedTodoList) => {
          if (!oldData) return undefined;
          return {
            ...oldData,
            results: oldData.results.map((todo) =>
              todo.id === id ? { ...todo, ...todoRequest } : todo
            ),
          };
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["article-todos", { feedId, companyId, articleId }],
      });
    },
  });

  const deleteTodo = useMutation({
    mutationFn: (id: number) =>
      client.horizonScanning.horizonFeedsArticlesTodosDestroy({
        feedId,
        companyId,
        articleId,
        id,
      }),
    onMutate: (id) => {
      queryClient.setQueryData(
        ["article-todos", { feedId, companyId, articleId }],
        (oldData?: PaginatedTodoList) => {
          if (!oldData) return undefined;

          return {
            ...oldData,
            results: oldData.results.filter((todo) => todo.id !== id),
          };
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["article-todos", { feedId, companyId, articleId }],
      });
    },
  });
  return {
    createTodo,
    updateTodo,
    deleteTodo,
  };
};
