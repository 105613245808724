/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PatchedTaskRequestPriority } from './PatchedTaskRequestPriority';
import {
    PatchedTaskRequestPriorityFromJSON,
    PatchedTaskRequestPriorityFromJSONTyped,
    PatchedTaskRequestPriorityToJSON,
} from './PatchedTaskRequestPriority';

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    dueDate?: Date | null;
    /**
     * 
     * @type {PatchedTaskRequestPriority}
     * @memberof Task
     */
    priority?: PatchedTaskRequestPriority | null;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    updatedBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    team: number;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    owner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    status?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    agreement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    policy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    article?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    directory?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    document?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    note?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    mitigation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    incident?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    risk?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    riskv2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    articlev2?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Task
     */
    assignees?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Task
     */
    mentions?: Array<number>;
}

/**
 * Check if a given object implements the Task interface.
 */
export function instanceOfTask(value: object): value is Task {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('team' in value) || value['team'] === undefined) return false;
    return true;
}

export function TaskFromJSON(json: any): Task {
    return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Task {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'dueDate': json['due_date'] == null ? undefined : (new Date(json['due_date'])),
        'priority': json['priority'] == null ? undefined : PatchedTaskRequestPriorityFromJSON(json['priority']),
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'createdBy': json['created_by'] == null ? undefined : json['created_by'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
        'team': json['team'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'status': json['status'] == null ? undefined : json['status'],
        'agreement': json['agreement'] == null ? undefined : json['agreement'],
        'policy': json['policy'] == null ? undefined : json['policy'],
        'article': json['article'] == null ? undefined : json['article'],
        'directory': json['directory'] == null ? undefined : json['directory'],
        'document': json['document'] == null ? undefined : json['document'],
        'note': json['note'] == null ? undefined : json['note'],
        'mitigation': json['mitigation'] == null ? undefined : json['mitigation'],
        'incident': json['incident'] == null ? undefined : json['incident'],
        'risk': json['risk'] == null ? undefined : json['risk'],
        'riskv2': json['riskv2'] == null ? undefined : json['riskv2'],
        'articlev2': json['articlev2'] == null ? undefined : json['articlev2'],
        'assignees': json['assignees'] == null ? undefined : json['assignees'],
        'mentions': json['mentions'] == null ? undefined : json['mentions'],
    };
}

export function TaskToJSON(value?: Omit<Task, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'due_date': value['dueDate'] == null ? undefined : ((value['dueDate'] as any).toISOString().substring(0,10)),
        'priority': PatchedTaskRequestPriorityToJSON(value['priority']),
        'name': value['name'],
        'description': value['description'],
        'created_by': value['createdBy'],
        'updated_by': value['updatedBy'],
        'team': value['team'],
        'owner': value['owner'],
        'status': value['status'],
        'agreement': value['agreement'],
        'policy': value['policy'],
        'article': value['article'],
        'directory': value['directory'],
        'document': value['document'],
        'note': value['note'],
        'mitigation': value['mitigation'],
        'incident': value['incident'],
        'risk': value['risk'],
        'riskv2': value['riskv2'],
        'articlev2': value['articlev2'],
        'assignees': value['assignees'],
        'mentions': value['mentions'],
    };
}

