/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedTodoRequest
 */
export interface PatchedTodoRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedTodoRequest
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedTodoRequest
     */
    isCompleted?: boolean;
}

/**
 * Check if a given object implements the PatchedTodoRequest interface.
 */
export function instanceOfPatchedTodoRequest(value: object): value is PatchedTodoRequest {
    return true;
}

export function PatchedTodoRequestFromJSON(json: any): PatchedTodoRequest {
    return PatchedTodoRequestFromJSONTyped(json, false);
}

export function PatchedTodoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedTodoRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'] == null ? undefined : json['description'],
        'isCompleted': json['is_completed'] == null ? undefined : json['is_completed'],
    };
}

export function PatchedTodoRequestToJSON(value?: PatchedTodoRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'is_completed': value['isCompleted'],
    };
}

