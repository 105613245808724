import { useEffect, useRef, useState } from "react";

import { cn } from "lib/utils";
import { DropdownItemProps } from "shared/typesV2";
import { Todo, TodoRequest } from "openapi";

import Checkbox from "shared/componentsV2/form-controls/checkbox";
import Dropdown from "shared/componentsV2/common-table/DropDown";
import Icon from "shared/components/icon/Icon";
import styles from "assets/css/articlesV2.module.css";
interface TodoItemProps {
  todo: Todo;
  updateTodo: (todo: TodoRequest & { id: number }) => void;
  deleteTodo: (id: number) => void;
}

const TodoItem = ({ todo, updateTodo, deleteTodo }: TodoItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const data: DropdownItemProps[] = [
    {
      id: 0,
      title: "Delete",
      icon: "trash-new",
      iconClassName: "theme_icon",
      iconFill: true,
    },
  ];

  const handleUpdate = (value: string) => {
    if (value.trim() === "") {
      setIsEditing(false);
      return;
    }
    updateTodo({ ...todo, description: value });
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleUpdate(e.currentTarget.value);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleUpdate(e.currentTarget.value);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.select();
      }, 100);
    }
  }, [isEditing]);

  return (
    <li
      className={cn(
        styles.article_to_do,
        "flex items-center rounded-lg px-3 py-2 bg-ghostwhite dark:bg-balticsea dark:border dark:border-jet dark:text-aurometalsaurus"
      )}
    >
      <div
        className={cn(
          "flex items-center gap-2",
          todo.isCompleted && !isEditing && "line-through text-aurometalsaurus"
        )}
      >
        <Checkbox
          checked={todo?.isCompleted}
          wrapperClassName="!p-0"
          className="w-3.5 h-3.5"
          onChange={() => updateTodo({ ...todo, isCompleted: !todo.isCompleted })}
        />

        <input
          ref={inputRef}
          disabled={todo.isCompleted}
          defaultValue={todo.description}
          className="w-full !bg-transparent text-sm font-medium grow !px-0 !py-1"
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
      </div>
      <Dropdown
        isPortal={false}
        className="!min-w-[11.5rem]"
        dropdownOptions={data}
        TriggerComponent={
          <button className="rounded-sm p-0 ml-auto">
            <Icon
              type="ellipsis-verticals"
              fill={true}
              size="icon-xs"
              className="fill_current_icon text-davygrey dark:text-silvers p-0 rounded-sm cursor-pointer"
            />
          </button>
        }
        handleSelect={() => deleteTodo(todo.id)}
      />
    </li>
  );
};

export default TodoItem;

interface CreateTodoItemProps {
  createTodo: (todo: TodoRequest) => void;
}

export const CreateTodoItem = ({ createTodo }: CreateTodoItemProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEdit = (value: string) => {
    if (value.trim() === "") return;
    createTodo({ description: value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleEdit(e.currentTarget.value);
      e.currentTarget.value = "";
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleEdit(e.currentTarget.value);
    e.currentTarget.value = "";
  };

  return (
    <li
      className={cn(
        styles.article_to_do,
        "grid items-center grid-cols-[auto_1fr_auto] gap-2 rounded-lg px-3 py-2 bg-ghostwhite dark:bg-balticsea"
      )}
    >
      <Checkbox checked={false} disabled wrapperClassName="!p-0" className="w-3.5 h-3.5" />
      <input
        ref={inputRef}
        defaultValue={""}
        className="w-full !bg-transparent text-sm font-medium grow !px-0 !py-1"
        placeholder="Type a new todo"
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
    </li>
  );
};
