import { useState } from "react";

import clsx from "clsx";
import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";
import { getImpactColor, getImpactLabel } from "modules/horizonScanning/utils";
import { ArticleImpactEnum } from "openapi";

import * as DropdownMenuDefault from "@radix-ui/react-dropdown-menu";

import Icon from "shared/components/icon/Icon";

const ArticleImpactDropdown = ({ disabled }: { disabled?: boolean }) => {
  const [openComboBox, setOpenComboBox] = useState(false);
  const {
    addImpact,
    articleQuery: { data: article },
  } = useArticleContext();

  if (!article) return null;

  return (
    <DropdownMenuDefault.Root open={openComboBox} onOpenChange={setOpenComboBox}>
      <DropdownMenuDefault.Trigger asChild disabled={disabled} className="rounded-lg">
        <button
          disabled={disabled}
          className="flex w-fit items-center gap-2 px-0 py-0.5 border-none rounded-lg justify-between disabled:text-santagrey"
        >
          {article.impact && article.impact !== ArticleImpactEnum.NotSet ? (
            <div className="flex gap-2 items-center">
              <Icon type="arrows-pointing-in" fill badgeColor={getImpactColor(article.impact)} />
              <span
                className={clsx("text-sm font-medium", {
                  "text-richblack dark:text-lighthouse": !disabled,
                  "text-santagrey dark:text-gray_400": disabled,
                })}
              >
                {getImpactLabel(article.impact)}
              </span>
            </div>
          ) : (
            <span className="text-sm font-inter-medium text-santagrey dark:text-gray_400">
              Set the impact
            </span>
          )}
          <Icon type="chevron-down" size="icon-sm" fill={true} />
        </button>
      </DropdownMenuDefault.Trigger>
      <DropdownMenuDefault.Content
        className="DropdownMenuContent min-w-[10rem] w-fit"
        sideOffset={5}
        side={"bottom"}
        align={"start"}
      >
        {Object.values(ArticleImpactEnum)
          .filter((e) => e !== ArticleImpactEnum.NotSet)
          ?.map((impact) => {
            return (
              <DropdownMenuDefault.Item
                key={impact}
                className="DropdownMenuItem"
                onClick={() => {
                  addImpact(impact);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex gap-2 items-center">
                    <Icon type="arrows-pointing-in" fill badgeColor={getImpactColor(impact)} />
                    <span>{getImpactLabel(impact)}</span>
                  </div>
                  {impact === article.impact && (
                    <Icon type="checkmark" fill={true} size="icon-sm" />
                  )}
                </div>
              </DropdownMenuDefault.Item>
            );
          })}
      </DropdownMenuDefault.Content>
    </DropdownMenuDefault.Root>
  );
};

export default ArticleImpactDropdown;
