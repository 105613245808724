/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Publisher
 */
export interface Publisher {
    /**
     * 
     * @type {number}
     * @memberof Publisher
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof Publisher
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Publisher
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    abbreviation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof Publisher
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Publisher
     */
    version?: number;
    /**
     * 
     * @type {number}
     * @memberof Publisher
     */
    region: number;
}

/**
 * Check if a given object implements the Publisher interface.
 */
export function instanceOfPublisher(value: object): value is Publisher {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('slug' in value) || value['slug'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('region' in value) || value['region'] === undefined) return false;
    return true;
}

export function PublisherFromJSON(json: any): Publisher {
    return PublisherFromJSONTyped(json, false);
}

export function PublisherFromJSONTyped(json: any, ignoreDiscriminator: boolean): Publisher {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'slug': json['slug'],
        'title': json['title'],
        'url': json['url'],
        'name': json['name'],
        'abbreviation': json['abbreviation'] == null ? undefined : json['abbreviation'],
        'description': json['description'],
        'isActive': json['is_active'] == null ? undefined : json['is_active'],
        'version': json['version'] == null ? undefined : json['version'],
        'region': json['region'],
    };
}

export function PublisherToJSON(value?: Omit<Publisher, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'slug': value['slug'],
        'title': value['title'],
        'url': value['url'],
        'name': value['name'],
        'abbreviation': value['abbreviation'],
        'description': value['description'],
        'is_active': value['isActive'],
        'version': value['version'],
        'region': value['region'],
    };
}

