import { createContext, useContext } from "react";

import { PaginatedArticleList } from "openapi";

import { UseInfiniteQueryResult } from "@tanstack/react-query";

import useSimilarArticles from "../hooks/useSimilarArticles";

type SimilarArticleContextProps = {
  feedId: number;
  articleId: number;
  companyId: number;
  pageSize?: number | undefined;
};

type SimilarArticleContextType = {
  similarArticlesQuery: UseInfiniteQueryResult<PaginatedArticleList, unknown>;
} & SimilarArticleContextProps;

const SimilarArticleContext = createContext<SimilarArticleContextType>(
  {} as SimilarArticleContextType
);

export const SimilarArticleProvider = ({
  children,
  ...props
}: { children: React.ReactNode } & SimilarArticleContextProps) => {
  const { feedId, articleId, pageSize } = props;
  const similarArticlesQuery = useSimilarArticles({
    feedId,
    articleId,
    filters: {},
    pageSize,
    isFilterReady: true,
  });

  return (
    <SimilarArticleContext.Provider
      value={{
        ...props,
        similarArticlesQuery,
      }}
    >
      {children}
    </SimilarArticleContext.Provider>
  );
};

export const useSimilarArticleContext = () => useContext(SimilarArticleContext);
