import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";
import { useArticleTodos } from "modules/horizonScanning/hooks/useArticleTodos";

import Modal from "shared/componentsV2/common-modal";
import Button from "shared/componentsV2/form-controls/button";
import { useArticleTodoCreate } from "modules/horizonScanning/hooks/useArticleTodos";

import { TodoItemIcon } from "./TodoItemIcon";
import TodoItem, { CreateTodoItem } from "./TodoItem";

const ArticleToDosModal = () => {
  const { showToDosModal, setShowToDosModal } = useArticleContext();
  const todoResponse = useArticleTodos();
  const articleTodo = useArticleTodoCreate();

  if (todoResponse.isLoading) return null;
  if (!todoResponse.data) return null;
  const todosFormState = todoResponse.data.results;
  const completedTodosAmount = todosFormState.filter((e) => e.isCompleted).length;
  const totalTodosAmount = todosFormState.length;

  return (
    <Modal
      onClose={() => setShowToDosModal(false)}
      show={showToDosModal}
      size="4xl"
      dismissible={false}
    >
      <Modal.Header>
        <div className="flex items-center gap-2 text-sm font-medium text-richblack dark:text-gray-400">
          <TodoItemIcon
            completedTodosAmount={completedTodosAmount}
            totalTodosAmount={totalTodosAmount}
          />
          To-do
          <span>
            {completedTodosAmount}/{totalTodosAmount}
          </span>
        </div>
      </Modal.Header>
      <Modal.Body className="!px-8 !py-6">
        <div className="grid gap-4">
          <span className="text-sm font-medium text-aurometalsaurus">
            The below tasks must be completed before the article status can be changed to complete.
          </span>
          <ul className="grid gap-2 list-none w-full text-sm font-medium">
            {todosFormState.map((todo) => (
              <TodoItem
                key={todo.id}
                todo={todo}
                updateTodo={articleTodo.updateTodo.mutateAsync}
                deleteTodo={articleTodo.deleteTodo.mutateAsync}
              />
            ))}
            <CreateTodoItem createTodo={articleTodo.createTodo.mutateAsync} />
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-end items-center !px-8 !py-3 border-0">
        <Button
          btnTitle="Close"
          btnType="secondary"
          btnSize="sm"
          onClick={() => setShowToDosModal(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ArticleToDosModal;
