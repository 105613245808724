import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { Tooltip } from "flowbite-react";
import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";
import { useFeedItemActionState } from "modules/horizonScanning/hooks/useFeedActionState";
import useFeed from "modules/horizonScanning/shared/hooks/useFeed";

import styles from "assets/css/articlesV2.module.css";

import Icon from "shared/components/icon/Icon";

const ActionBar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const stickyActionsRef = useRef<HTMLDivElement>(null);

  const {
    articleId,
    companyId,
    feedId,
    isPublic,
    articleQuery: { data: article },
    setShareModalArticle,
    ...rest
  } = useArticleContext();

  const stateAction = useFeedItemActionState({ feedId, articleId });

  const { data: feed } = useFeed({ feedId });

  if (!article) return null;
  const isRead = stateAction.isRead ?? article.isRead;
  const isSaved = stateAction.isSaved ?? article.isSaved;
  const isArchived = stateAction.isArchived ?? article.isArchived;

  const actions = [
    {
      tooltip: isRead ? "This article was marked as read" : "Mark as read",
      icon: "book-open",
      fill: isRead,
      badgeColor: isRead ? "icon-blue-600" : "inherit",
      onClick: isRead ? rest.unread : rest.read,
      hide: isPublic,
    },
    {
      tooltip: isSaved ? "Saved" : "Save",
      icon: "bookmark",
      fill: isSaved,
      onClick: isSaved ? rest.unsave : rest.save,
      hide: isPublic,
    },
    {
      tooltip: isArchived ? "Archived" : "Archive",
      icon: isArchived ? "archive-box-fill" : "archive-box",
      className: isArchived ? "icon-blue-600-no-stroke" : "inherit",
      fill: isArchived,
      onClick: isArchived ? rest.unarchive : rest.archive,
      hide: isPublic,
    },
    {
      tooltip: "Share",
      icon: "share",
      onClick: () => setShareModalArticle(article),
    },
  ];

  useEffect(() => {
    if (stickyActionsRef.current?.parentElement) {
      const handleParentScroll = (event: Event) => {
        if (event.target instanceof HTMLDivElement && event.target.scrollTop >= 32) {
          setIsSticky(true);
          return;
        }

        setIsSticky(false);
      };

      stickyActionsRef.current?.parentElement.addEventListener("scroll", handleParentScroll);

      return () => {
        stickyActionsRef.current?.parentElement?.removeEventListener("scroll", handleParentScroll);
      };
    }
  }, []);

  return (
    <div
      className={clsx(
        "sticky -top-8 flex items-center justify-between gap-2",
        styles.article_details_actions,
        {
          "py-3 px-8 shadow-sm bg-white dark:bg-mirage !col-start-1 !col-end-4": isSticky,
        }
      )}
      ref={stickyActionsRef}
    >
      {!isPublic ? (
        <div className="flex gap-2 vendor_pagination_button">
          {feed && (
            <Link
              to={`/workspace/${companyId}/horizon-scanning/feeds/${feedId}`}
              className="font-medium text-xs"
            >
              <button className="border px-1.5 py-0.5 h-5 rounded-md dark:text-gray-400">
                {feed.name}
              </button>
            </Link>
          )}

          {!isPublic ? (
            <div className="flex">
              <div className="border border-r-0 font-medium text-xs px-1.5 py-0.5 h-5 rounded-l-md">
                <PaginationIcon
                  to={`/workspace/${companyId}/horizon-scanning/feeds/${feedId}/article/${article.previous}`}
                  iconType="leftarrow"
                  isDisabled={!article.previous}
                />
              </div>
              <div className="border font-medium text-xs px-1.5 py-0.5 h-5 rounded-r-md">
                <PaginationIcon
                  to={`/workspace/${companyId}/horizon-scanning/feeds/${feedId}/article/${article.next}`}
                  iconType="rightarrow"
                  isDisabled={!article.next}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <Link
          to="https://www.grand.io"
          className="font-medium text-xs"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="px-2 py-2 rounded-md flex items-center gap-2 !bg-darkJungleGreen">
            <Icon type="post" fill={false} size="icon-xs" className="notification_icon" />
            <h4
              data-testid="unauthorized-warning"
              className="text-xs text-white font-medium dark:text-mirage"
            >
              Article shared from Grand's news monitoring service
            </h4>
          </button>
        </Link>
      )}
      <div className="flex gap-3">
        {actions
          .filter((action) => !action.hide)
          .map((action) => (
            <Tooltip
              key={action.icon}
              className="whitespace-nowrap metadata_tooltip"
              content={action.tooltip}
              arrow={false}
            >
              <div onClick={action.onClick}>
                <Icon
                  className={clsx(
                    "cursor-pointer",
                    {
                      "icon-blue-600": action.fill,
                    },
                    action.className
                  )}
                  type={action.icon}
                  size="icon-xs"
                  fill={action.fill}
                  badgeColor={action?.badgeColor}
                />
              </div>
            </Tooltip>
          ))}
      </div>
    </div>
  );
};

const PaginationIcon = ({
  to,
  iconType,
  isDisabled,
}: {
  to: string;
  iconType: string;
  isDisabled: boolean;
}) =>
  isDisabled ? (
    <span className="opacity-20 pointer-events-none">
      <Icon type={iconType} fill={true} size="icon-xs" className="pagination_arrow" />
    </span>
  ) : (
    <Link to={to}>
      <Icon type={iconType} fill={true} size="icon-xs" className="pagination_arrow" />
    </Link>
  );

export default ActionBar;
