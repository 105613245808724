import { client } from "client";

import { useInfiniteQuery } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";

import { FeedFiltersOmitted } from "../constants";

const ITEMS_PER_PAGE = 10;

const useSimilarArticles = ({
  feedId,
  articleId,
  filters,
  pageSize,
  isFilterReady = true,
}: {
  feedId: number;
  articleId: number;
  filters: FeedFiltersOmitted;
  pageSize?: number | undefined;
  isFilterReady?: boolean;
}) => {
  const { currentCompany } = useCompany();
  return useInfiniteQuery({
    queryKey: ["similar-articles", feedId, currentCompany.id, filters, articleId],
    queryFn: ({ pageParam = 1 }) =>
      client.horizonScanning.horizonFeedsArticlesList({
        ...filters,
        feedId,
        companyId: currentCompany.id,
        similarTo: Number(articleId),
        page: pageParam,
        pageSize: pageSize || ITEMS_PER_PAGE,
      }),
    enabled: !!articleId && !!feedId && isFilterReady,
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });
};

export default useSimilarArticles;
