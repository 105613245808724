import { useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import { Tooltip } from "flowbite-react";
import { EnvelopeIcon } from "@heroicons/react/24/solid";

import { Article } from "openapi";

import Icon from "shared/components/icon/Icon";
import Modal from "shared/componentsV2/common-modal";

interface ShareArticleModalProps {
  open: boolean;
  onClose: () => void;
  article: Article;
}

const ShareArticleModal = ({ article, open, onClose }: ShareArticleModalProps) => {
  const [copyConfirmation, setCopyConfirmation] = useState(false);
  const articlePublicURL = `${window.location.origin}/articles/${article.id}/public`;
  const copyLink = () => {
    navigator.clipboard.writeText(articlePublicURL);
    setCopyConfirmation(true);
    setTimeout(() => setCopyConfirmation(false), 2000);
  };

  const sliceDescription = (description: string, limit: number) => {
    if (description.length > limit) {
      return `${description.slice(0, limit)}...`;
    }
    return description;
  };

  return (
    <Modal show={open} onClose={onClose} size="xl">
      <Modal.Header>
        <span className="text-lg font-medium text-gray-800 dark:text-white">Share</span>
      </Modal.Header>
      <Modal.Body>
        <div className="grid gap-2">
          <h3 className="text-sm font-medium text-gray-800 dark:text-white">{article.title}</h3>
          <p className="text-gray-500 dark:text-gray-400 text-sm">
            {sliceDescription(article.description || "", 150)}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full grid gap-4">
          <div className="grid gap-1">
            <h5 className="font-medium text-xs text-gray-600 dark:text-white">Copy link</h5>
            <div className="flex items-center gap-2 border border-gray-200 rounded-md p-2 dark:border-jet">
              <span className="w-full font-medium text-sm text-gray-700 dark:text-gray-500">
                {articlePublicURL}
              </span>
              <Tooltip
                arrow={false}
                content={copyConfirmation ? "Copied!" : "Copy"}
                className="metadata_tooltip"
              >
                <button onClick={copyLink}>
                  <Icon type="document-duplicate" size="icon-xs" fill />
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="grid gap-1">
            <h5 className="font-medium text-xs text-gray-600 dark:text-white">Or share via</h5>
            <div className="flex space-x-4">
              <FacebookShareButton url={articlePublicURL}>
                <Icon type="facebook" className="w-5 h-5 stroke-none" />
              </FacebookShareButton>
              <LinkedinShareButton url={articlePublicURL}>
                <Icon type="linkedin" className="w-5 h-5 stroke-none" />
              </LinkedinShareButton>
              <TwitterShareButton url={articlePublicURL}>
                <Icon type="x" className="w-5 h-5 stroke-none [&>path]:dark:fill-white" />
              </TwitterShareButton>
              <EmailShareButton url={articlePublicURL}>
                <EnvelopeIcon className="w-5 h-5 dark:fill-white" />
              </EmailShareButton>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareArticleModal;
