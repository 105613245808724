import { AGREEMENT_TYPE, NOTE_TYPE, POLICY_TYPE } from "shared/helpers/constant";

import { isNoteFileResponse } from "modules/notes/helpers";
import { isPolicyFileResponse } from "modules/policies/overview/helpers";
import { isAgreementFileType } from "modules/agreements/overview/helpers";

import { FileResponsePreparer, LexicalFileData } from "../types";
import { isUploadableModule } from "./index";

const defaultFileResponsePreparer = () => null;

function preparePolicyFileResponse(value: unknown): LexicalFileData | null {
  if (!isPolicyFileResponse(value)) {
    console.warn("Can not prepare file response. Value is not PolicyFileResponse");
    return null;
  }

  return {
    id: value.id,
    name: value.name,
    url: value.file,
    moduleType: POLICY_TYPE,
  };
}

function prepareAgreementFileResponse(value: unknown): LexicalFileData | null {
  if (!isAgreementFileType(value)) {
    console.warn("Can not prepare file response. Value is not AgreementFilesType");
    return null;
  }

  return {
    id: value.id,
    name: value.file,
    url: value.file,
    moduleType: AGREEMENT_TYPE,
  };
}

function prepareNoteFileResponse(value: unknown): LexicalFileData | null {
  if (!isNoteFileResponse(value)) {
    console.warn("Can not prepare file response. Value is not NoteFileResponse");
    return null;
  }

  return {
    id: value.id,
    name: value.name,
    url: value.file,
    moduleType: NOTE_TYPE,
  };
}

export default function getFileResponsePreparer(type: string): FileResponsePreparer {
  if (!isUploadableModule(type)) {
    return defaultFileResponsePreparer;
  }

  switch (type) {
    case POLICY_TYPE: {
      return preparePolicyFileResponse;
    }
    case AGREEMENT_TYPE: {
      return prepareAgreementFileResponse;
    }
    case NOTE_TYPE: {
      return prepareNoteFileResponse;
    }
    default: {
      const _exhaustiveCheck: never = type;
      console.warn("Exhaustive check failed: ", _exhaustiveCheck);
      return defaultFileResponsePreparer;
    }
  }
  return defaultFileResponsePreparer;
}
