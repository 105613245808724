import get from "lodash/get";

import { CompanyResponse, UserRole } from "modules/home/types";

import { defaultSideBarItems } from "./sidebar";
import {
  AGREEMENT_MODULE,
  ARTICLE_MODULE,
  CALENDAR_MODULE,
  DASHBOARD_MODULE,
  DOCUMENT_MODULE,
  NOTE_MODULE,
  POLICY_MODULE,
  RISK_MODULE,
  TASK_MODULE,
  REPORT_MODULE,
  AppAbility,
  VIEW_ACTION,
  VENDOR_MODULE,
  ASSISTANT_MODULE,
  QUESTIONNAIRE_MODULE,
} from "./ability";

export default function getVisibleModuleForCompany(
  company: CompanyResponse,
  ability: AppAbility,
  role?: UserRole
) {
  const modules = company.modules;
  const map = {
    [CALENDAR_MODULE]: "calendar",
    [ARTICLE_MODULE]: "articles",
    [TASK_MODULE]: "tasks",
    [DOCUMENT_MODULE]: "documents",
    [POLICY_MODULE]: "policies",
    [ASSISTANT_MODULE]: "assistant",
    [RISK_MODULE]: "risks",
    [DASHBOARD_MODULE]: "dashboard",
    [AGREEMENT_MODULE]: "agreements",
    [NOTE_MODULE]: "notes",
    [REPORT_MODULE]: "report",
    [VENDOR_MODULE]: "thirdparty",
    [QUESTIONNAIRE_MODULE]: "questionnaires",
  } as Record<string, string>;

  const visibleModule = defaultSideBarItems(company.id).find((sidebar) => {
    if (role === "GUEST") {
      return sidebar.subject === QUESTIONNAIRE_MODULE;
    }
    if (sidebar.subject === VENDOR_MODULE) {
      return (
        get(modules, map[sidebar.subject || ""])?.enabled &&
        get(modules, map[sidebar.subject || ""])?.vendors_enabled
      );
    }
    if (
      (sidebar?.subject && ability.can(VIEW_ACTION, sidebar?.subject)) ||
      sidebar.subject === REPORT_MODULE
    ) {
      return get(modules, map[sidebar.subject || ""])?.enabled;
    }
  });

  return visibleModule;
}
