import { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { GroupBase, MultiValue, OptionProps } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { components } from "react-select";
import { useDarkMode } from "usehooks-ts";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse, AxiosError } from "axios";
import { toast } from "react-toastify";

import { getServerErrors } from "shared/helpers/util";
import { getTagsList, deleteTagById } from "shared/components/metadata/api";
import { ICONTAINS_OPERATOR } from "shared/helpers/constant";
import { RefetchOptions } from "shared/components/common-table/types";
import { selectControlStyleForCreate } from "shared/helpers/selectStyle";
import { TagsResponseType, optionType, tagsType } from "shared/components/metadata/types";
import { useTeam } from "shared/store/settings";
import getQueryParamsFromRefetchOptions from "shared/helpers/getQueryParamsFromRefetchOptions";
import headerStyles from "assets/css/common-header.module.css";

import Icon from "../icon/Icon";

interface TagsFieldProps {
  values: optionType[];
  onChange: (data: MultiValue<optionType>) => void;
  teamId: number | undefined;
  isDisabled?: boolean;
  controlShouldRenderValue?: boolean;
}

export const TagsField = ({
  teamId,
  values,
  onChange,
  isDisabled = false,
  controlShouldRenderValue = true,
}: TagsFieldProps) => {
  const { isDarkMode } = useDarkMode();

  const [tags, setTags] = useState<Array<optionType>>([]);
  const currentTeam = useTeam((state) => state.currentTeam);
  const selectedTag = useRef<number | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const setTagsOption = (res: AxiosResponse<TagsResponseType>) => {
    const list: Array<optionType> = [];
    res.data.results.forEach((item: tagsType) => {
      list.push({ id: item.id, label: item.name, value: item.name });
    });
    setTags(list);
    return list;
  };

  const { isLoading, mutateAsync: getTags } = useMutation(
    ["getTagsList", teamId],
    ({ refetchOptionForTag }: { refetchOptionForTag?: Array<RefetchOptions> }) =>
      getTagsList(getQueryParamsFromRefetchOptions(refetchOptionForTag), {
        team: { team: teamId, company: currentTeam.company, isAllTeam: currentTeam.is_all_team },
      }),
    {
      onSuccess: (res) => {
        res && setTagsOption(res);
      },
    }
  );

  const promiseOptions = (inputValue: string) => {
    return new Promise<optionType[]>((resolve, reject) => {
      const result = getTags({
        refetchOptionForTag: [
          {
            field: "name__" + ICONTAINS_OPERATOR,
            value: inputValue,
            type: "name",
          },
        ],
      });
      result
        .then((res) => {
          res && resolve(setTagsOption(res));
        })
        .catch((err) => reject(err));
    });
  };

  useEffect(() => {
    if (getTags) {
      getTags({});
    }
  }, []);

  const { mutate: deleteTagFn } = useMutation(
    ({ tagId, teamId }: { tagId: number; teamId: number }) => deleteTagById({ tagId, teamId }),
    {
      onSuccess: () => {
        toast("Tag Deleted Successfully", { type: "success" });
        selectedTag.current = null;
        setIsMenuOpen(false);
      },
      onError: (error: AxiosError) => {
        {
          getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
        }
      },
    }
  );

  const Option = (props: OptionProps<optionType, boolean, GroupBase<optionType>>) => {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseOver = () => {
      setIsHovering(true);
    };

    const handleMouseOut = () => {
      setIsHovering(false);
    };
    return (
      <components.Option {...props}>
        <div
          className="flex justify-between flex-row items-center"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div className="font-medium"> {props.data.label} </div>
          {isHovering && props.data?.id ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (props.data?.id) {
                  deleteTagFn({ tagId: props.data.id, teamId: currentTeam.id });
                }
              }}
            >
              <Icon type="close" fill={false} size="icon-xs" className="tagclose_icon" />
            </button>
          ) : null}
        </div>
      </components.Option>
    );
  };

  return (
    <>
      <AsyncCreatableSelect
        isDisabled={isDisabled}
        components={{ Option }}
        controlShouldRenderValue={controlShouldRenderValue}
        onMenuOpen={() => {
          getTags({});
          setIsMenuOpen(true);
        }}
        isLoading={isLoading}
        loadOptions={promiseOptions}
        onMenuClose={() => {
          setTags([]);
          setIsMenuOpen(false);
        }}
        allowCreateWhileLoading
        onInputChange={(input, actionMeta) => {
          if (actionMeta.action === "input-change" && !input) getTags({});
        }}
        defaultOptions={tags}
        options={tags}
        placeholder="select tags"
        classNamePrefix="multitag multiTaskTag multitag_spacing"
        className={clsx(headerStyles?.searchField)}
        value={values}
        isClearable={false}
        isMulti
        styles={selectControlStyleForCreate(isDarkMode)}
        onChange={(value) => {
          if (value && Array.isArray(value)) {
            onChange(value);
          }
        }}
        menuIsOpen={isMenuOpen}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#E5E7EB",
          },
        })}
      />
    </>
  );
};
