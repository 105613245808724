/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TagRequest } from './TagRequest';
import {
    TagRequestFromJSON,
    TagRequestFromJSONTyped,
    TagRequestToJSON,
} from './TagRequest';

/**
 * 
 * @export
 * @interface PatchedFeedRequest
 */
export interface PatchedFeedRequest {
    /**
     * 
     * @type {Array<TagRequest>}
     * @memberof PatchedFeedRequest
     */
    tags?: Array<TagRequest>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedFeedRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedFeedRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedFeedRequest
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedFeedRequest
     */
    isArchived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedFeedRequest
     */
    smartFilter?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedFeedRequest
     */
    keywordsOr?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedFeedRequest
     */
    keywordsAnd?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedFeedRequest
     */
    keywordsNot?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof PatchedFeedRequest
     */
    publicationStartDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PatchedFeedRequest
     */
    publicationEndDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedFeedRequest
     */
    totalArticlesCount?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedFeedRequest
     */
    publishers?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedFeedRequest
     */
    jurisdictions?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedFeedRequest
     */
    institutions?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedFeedRequest
     */
    sections?: Array<number>;
}

/**
 * Check if a given object implements the PatchedFeedRequest interface.
 */
export function instanceOfPatchedFeedRequest(value: object): value is PatchedFeedRequest {
    return true;
}

export function PatchedFeedRequestFromJSON(json: any): PatchedFeedRequest {
    return PatchedFeedRequestFromJSONTyped(json, false);
}

export function PatchedFeedRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedFeedRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'tags': json['tags'] == null ? undefined : ((json['tags'] as Array<any>).map(TagRequestFromJSON)),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'isArchived': json['is_archived'] == null ? undefined : json['is_archived'],
        'smartFilter': json['smart_filter'] == null ? undefined : json['smart_filter'],
        'keywordsOr': json['keywords_or'] == null ? undefined : json['keywords_or'],
        'keywordsAnd': json['keywords_and'] == null ? undefined : json['keywords_and'],
        'keywordsNot': json['keywords_not'] == null ? undefined : json['keywords_not'],
        'publicationStartDate': json['publication_start_date'] == null ? undefined : (new Date(json['publication_start_date'])),
        'publicationEndDate': json['publication_end_date'] == null ? undefined : (new Date(json['publication_end_date'])),
        'totalArticlesCount': json['total_articles_count'] == null ? undefined : json['total_articles_count'],
        'publishers': json['publishers'] == null ? undefined : json['publishers'],
        'jurisdictions': json['jurisdictions'] == null ? undefined : json['jurisdictions'],
        'institutions': json['institutions'] == null ? undefined : json['institutions'],
        'sections': json['sections'] == null ? undefined : json['sections'],
    };
}

export function PatchedFeedRequestToJSON(value?: PatchedFeedRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tags': value['tags'] == null ? undefined : ((value['tags'] as Array<any>).map(TagRequestToJSON)),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'is_archived': value['isArchived'],
        'smart_filter': value['smartFilter'],
        'keywords_or': value['keywordsOr'],
        'keywords_and': value['keywordsAnd'],
        'keywords_not': value['keywordsNot'],
        'publication_start_date': value['publicationStartDate'] == null ? undefined : ((value['publicationStartDate'] as any).toISOString().substring(0,10)),
        'publication_end_date': value['publicationEndDate'] == null ? undefined : ((value['publicationEndDate'] as any).toISOString().substring(0,10)),
        'total_articles_count': value['totalArticlesCount'],
        'publishers': value['publishers'],
        'jurisdictions': value['jurisdictions'],
        'institutions': value['institutions'],
        'sections': value['sections'],
    };
}

