import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";

import ArticleSection from "./ArticleSection";

const ArticleSummary = () => {
  const {
    articleQuery: { data: article },
  } = useArticleContext();
  if (!article) return null;
  return (
    <ArticleSection title="Summary" icon="document-check">
      <span className="border-l-2 border-brightgray ml-6 pl-6 leading-relaxed text-base text-davygrey font-medium dark:text-gray-500 dark:border-jet">
        {article.description}
      </span>
    </ArticleSection>
  );
};

export default ArticleSummary;
