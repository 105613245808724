import { useQuery } from "@tanstack/react-query";

import { AssistantAgentsRetrieveRequest } from "openapi";
import { client } from "client";

export const useAssistantAIAgent = () => {
  const getAssistantAgents = () =>
    useQuery({
      queryKey: ["assistant-agents"],
      queryFn: () => client.assistant.assistantAgentsList(),
    });

  const getAssistantAgentById = ({ id }: AssistantAgentsRetrieveRequest) =>
    useQuery({
      queryKey: ["assistant-agent-by-id", id],
      queryFn: () => client.assistant.assistantAgentsRetrieve({ id }),
    });

  return {
    getAssistantAgents,
    getAssistantAgentById,
  };
};
